import React, { useContext, useEffect, useState } from "react";
import { FaRegClone, FaCheck, FaSyncAlt } from "react-icons/fa";
import copy from "copy-to-clipboard";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import CodeMirror from "@uiw/react-codemirror";
import { json } from "@codemirror/lang-json";
import App from "../../components/App";
import GlobalContext from "../../context/GlobalContext";
import { API_URL_V1 } from "../../config";
import Slider from "rc-slider";

const PLAYGROUND_DEFAULT_INPUT = `Hey jack you rock!`;

const SCALE = {
  0.1: { label: "Unlikely" },
  0.3: { label: "Maybe" },
  0.5: { label: "Likely" },
  0.7: { label: "Very Likely" },
  0.9: { label: "Certain" },
};

const Api = () => {
  const { session } = useContext(GlobalContext);
  const [copied, setCopied] = useState(false);
  const [creatingSecret, setCreatingSecret] = useState(false);
  const [secrets, setSecrets] = useState([]);
  const [playgroundInput, setPlaygroundInput] = useState(
    PLAYGROUND_DEFAULT_INPUT
  );
  const [playgroundErr, setPlaygroundErr] = useState(null);
  const [playgroundResponse, setPlaygroundResponse] = useState(null);
  const [loadingResponse, setLoadingResponse] = useState(false);

  useEffect(() => {
    if (!session) return;
    axios
      .get(`${API_URL_V1}/secrets`, {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      })
      .then((res) => {
        setSecrets(res.data);
      })
      .catch((err) => console.log(err));
  }, [session]);

  const onCopy = () => {
    if (copied) return;
    setCopied(true);
    copy(secrets[0]);
    setTimeout(() => setCopied(false), 1000);
  };

  const rotateSecret = () => {
    if (!session) return;
    setCreatingSecret(true);
    axios
      .post(`${API_URL_V1}/secrets/rotate`, null, {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      })
      .then((res) => {
        setSecrets([res.data]);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setCreatingSecret(false);
      });
  };

  const track = async () => {
    if (!secrets || !secrets.length || playgroundInput.length == 0) return;

    setPlaygroundErr(null);
    setPlaygroundResponse(null);
    setLoadingResponse(true);
    try {
      const parsed = {text: playgroundInput};
      axios
        .post(`${API_URL_V1}/test`, parsed, {
          headers: {
            Authorization: `Bearer ${secrets[0]}`,
          },
        })
        .then((result) => {
          setPlaygroundResponse(result.data);
        })
        .catch((err) => {
          setPlaygroundErr(err);
        })
        .finally(() => {
          setLoadingResponse(false);
        });
    } catch (error) {
      setLoadingResponse(false);
      setPlaygroundErr(error.toString());
    }
  };

  return (
    <App>
      <h4>Secrets</h4>
      <div className="input-group input-group-md w-50 pt-4">
        <input
          type="text"
          className="form-control"
          aria-label="secret"
          value={
            secrets[0]
              ? `${secrets[0]?.slice(0, 10)}...${secrets[0]?.slice(-10)}`
              : ""
          }
          disabled
        />
        <div className="input-group-append">
          <button
            className="btn btn-sm btn-outline-secondary"
            style={{ minWidth: "80px" }}
            type="button"
            onClick={onCopy}
            disabled={!secrets[0]}
            data-toggle="tooltip"
            data-placement="top"
            title="Copy"
          >
            {copied ? <FaCheck /> : <FaRegClone />}
          </button>
          <button
            className="btn btn-sm btn-outline-secondary"
            style={{ minWidth: "80px" }}
            type="button"
            onClick={rotateSecret}
            disabled={creatingSecret}
            data-toggle="tooltip"
            data-placement="top"
            title="Rotate Secret"
          >
            {creatingSecret ? (
              <Spinner className="spinner-border spinner-border-sm" />
            ) : (
              <FaSyncAlt />
            )}
          </button>
        </div>
      </div>
      <div className="mt-4">
        Keep the secret safe and avoid exposing it on the client
      </div>
      <h4 className="mt-16">HTTP API</h4>
      <h6>Track Moderation Event</h6>
      <span className="mr-6">POST</span>
      <code>https://api.modwise.ai/v1/track</code>
      <br />
      <span className="mr-6">Add Header</span>
      <code>Authorization: Bearer YOUR_SECRET</code>
      <br />
      Request Body
      <pre>{`{
    text: "<string>",
    meta: <json>
}`}</pre>
      You can optionally send additional metadata in the <code>meta</code>{" "}
      parameter
      <div class="alert alert-primary mt-4" role="alert">
        Note: To ensure privacy, we never store the text input in our databases.
      </div>
      <h6 className="mt-10">Playground</h6>
      <CodeMirror
        value={playgroundInput}
        height="100px"
        maxWidth="100%"
        onChange={(value) => setPlaygroundInput(value)}
      />
      <div className="mt-2">
        <div className="d-flex justify-content-between">
          <button
            onClick={track}
            className="btn btn-outline-primary rounded-5 btn-sm"
          >
            Moderate
          </button>
        </div>
        {playgroundErr && <span className="ml-4">{playgroundErr}</span>}
        {loadingResponse && <div className="mt-2 font-italic">Loading ...</div>}
        {playgroundResponse && (
          <>
            <Level label="Self-harm" w={playgroundResponse.mod["self-harm"] * 100} />
            <Level label="Hate" w={playgroundResponse.mod["hate"] * 100} />
            <Level label="Threatening" w={playgroundResponse.mod["hate/threatening"] * 100} />
            <Level label="Violence" w={playgroundResponse.mod["violence"] * 100} />
            <Level label="Sexual" w={playgroundResponse.mod["sexual"] * 100} />
            <Level label="Sexual/Minors" w={playgroundResponse.mod["sexual/minors"] * 100} />
            <Level label="Profanity" w={playgroundResponse.profanity ? 100 : 0} />

            <CodeMirror className="mt-10" value={JSON.stringify(playgroundResponse.mod, null, 2)} />
          </>
        )}
        {/* {chatResponse && <div className="mt-10">{chatResponse}</div>} */}
      </div>
    </App>
  );
};

/**
 *
 * @param {Object} props
 * @param {string} props.label
 * @param {number} props.w
 * @param {string} props.className
 * @returns
 */
const Level = ({ label, w, className }) => (
  <div className={`mt-10 d-flex ${className ? className : ""}`}>
    {label && <h5 className="w-25 mr-10">{label}</h5>}
    <div className="w-100">
      <div class="progress w-100">
        <div
          class="progress-bar"
          role="progressbar"
          style={{ width: `${w}%` }}
          aria-valuenow={w}
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <div className="d-flex">
        <div className="w-20">Unlikely</div>
        <div className="w-20 text-center">Maybe</div>
        <div className="w-20 text-center">Likely</div>
        <div className="w-20 text-center">Very Likely</div>
        <div className="w-20 text-right">Certain</div>
      </div>
    </div>
  </div>
);

export default Api;
